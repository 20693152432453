/* src/components/Counter.css */
.counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .subaio-logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .counter-display {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    font-size: 2rem;
  }
  
  .counter-buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .counter-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .counter-btn:hover {
    background-color: #218838;
  }
  